<template>
    <div class="container-fluid">
        <div class="row flex-nowrap overflow-hidden">
            <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark text-white">
                <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    <router-link to="/"
                        class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <img src="../assets/svg/Reserba_white.svg" width="40">
                        <span class="fs-5 d-none d-sm-inline m-3">Reserba</span>
                    </router-link>
                    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link align-middle px-0 text-white">
                                <i class="fs-5 bi bi-grid"></i> <span class="ms-1 d-none d-sm-inline">Dashboard</span>
                            </router-link>
                        </li>
                        <li>
                            <a href="#businessmenu" data-bs-toggle="collapse" class="nav-link px-0 align-middle text-white">
                                <i class="fs-5 bi-shop"></i> <span class="ms-1 d-none d-sm-inline">Businesses</span>
                                <i class="ms-2 bi bi-caret-down-fill"></i>
                            </a>
                            <ul class="collapse nav flex-column ms-1" id="businessmenu" data-bs-parent="#menu">
                                <li class="w-100">
                                    <router-link to="/establishments" class="nav-link px-0 align-middle text-white">
                                        <i class="bi bi-briefcase-fill"></i> <span
                                            class="ms-1 d-none d-sm-inline">Businesses</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/verifications" class="nav-link align-middle px-0 text-white">
                                        <i class="bi bi-slash-circle-fill"></i> <span class="ms-1 d-none d-sm-inline">Banned
                                            Businesses</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/verifications" class="nav-link align-middle px-0 text-white">
                                        <i class="bi bi-patch-check-fill"></i> <span
                                            class="ms-1 d-none d-sm-inline">Verifications</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/confirmations" class="nav-link align-middle px-0 text-white">
                                        <i class="bi bi-check-square-fill"></i> <span
                                            class="ms-1 d-none d-sm-inline">Approvals</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#usermenu" data-bs-toggle="collapse" class="nav-link px-0 align-middle text-white">
                                <i class="fs-5 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Users</span>
                                <i class="ms-2 bi bi-caret-down-fill"></i>
                            </a>
                            <ul class="collapse nav flex-column ms-1" id="usermenu" data-bs-parent="#menu">
                                <li class="w-100">
                                    <router-link to="/users" class="nav-link px-0 align-middle text-white"> <span
                                            class="d-none d-sm-inline">User Lists</span>
                                        <i class="d-sm-block d-md-none bi bi-table"></i>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/banuser" class="nav-link px-0"> <span
                                            class="d-none d-sm-inline text-white">Banned Users</span>
                                        <i class="d-sm-block d-md-none bi bi-ban"></i>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link to="/messages" class="nav-link align-middle px-0 text-white">
                                <i class="fs-5 bi bi-envelope"></i> <span class="ms-1 d-none d-sm-inline">Messages</span>
                            </router-link>
                        </li>
                        <!-- <hr class="w-100"> -->
                    </ul>
                    <hr>
                    <div class="dropdown pb-4">
                        <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                            id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30"
                                class="rounded-circle">
                            <span class="d-none d-sm-inline mx-1">{{ userDetail.name }}</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                            <li><a class="dropdown-item" href="#">Settings</a></li>
                            <li><a class="dropdown-item" href="#">Profile</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#" @click.prevent="logoutUser">Sign out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col vh-100 bg-light overflow-scroll">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    data: () => ({
        token: '',
    }),
    computed: {
        ...mapGetters('main', ['personalToken', 'userDetail'])
    },
    methods: {
        ...mapActions('main', ['logout', 'getUserDetails']),
        logoutUser() {
            this.logout(this.credentials).then(() => this.$router.push({ path: '/login' }))
        }
    },
    mounted() {
        console.log('token: ', this.personalToken)
        this.getUserDetails()
    }
}
</script>