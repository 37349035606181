<template>
    <Line v-if="isLoaded" :data="myLabels" :options="options" />
</template>
  
<script>

import { mapActions, mapGetters } from "vuex"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Bar, Line } from 'vue-chartjs'
// import * as chartConfig from './chartConfig.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
    name: 'App',
    components: {
        Line,
    },
    data() {
        return {
            isLoaded: false,
            monthly: [],
            myLabels: [],
            data: {
                labels: ['January', 'February', 'March'],
                backgroundColor: '#f87979',
                // datasets: [
                //     {
                //         label: this.myLabels,
                //         data: this.myLabels
                //     }
                // ]
                // datasets: [
                //     {
                //         label: 'Test',
                //         data: [40, 20, 12]
                //     }
                // ]
            },
            options: {
                responsive: true
            }
        }
    },
    computed: {
        ...mapGetters('main', ['monthlyUserGrowth'])
    },
    methods: {
        ...mapActions('main', ['getMonthlyUserGrowth'])
    },
    async mounted() {

        try {

            await this.getMonthlyUserGrowth()

            this.myLabels = this.monthlyUserGrowth.data.map(item => item.date)
            this.monthly = this.monthlyUserGrowth.data.map(item => item.total)
            this.isLoaded = true

            // console.log('myLabels: ', this.myLabels)
        } catch (e) {
            console.error(e)
        }
    }
}
</script>