
<template>
    <div v-if="isLoading" class="d-flex min-vh-100 justify-content-center align-items-center bg-transparent">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else class="py-3">
        <div class="container-fluid bg-white p-5 rounded table-responsive mh-100">
            <div class="d-flex flex-md-row flex-column mb-5">
                <img :src="establishment.image_path" alt="" class="image-fluid rounded-2 w-25 me-4 mb-sm-2">
                <div class="">
                    <div class="d-flex mb-lg-3 mb-0">
                        <h4 class="fs-2 placeholder-glow">{{ establishment.name }}</h4>
                        <i class="bi ms-1 text-primary"
                            :class="establishment.is_verified ? 'bi-patch-check-fill' : 'bi-patch-check'"></i>
                    </div>
                    <div class="d-flex">
                        <i class="bi bi-envelope-at-fill"></i>
                        <p class="ms-2">{{ establishment.user.email }}</p>
                    </div>
                    <div class="d-flex">
                        <i class="bi bi-geo-alt-fill"></i>
                        <p class="ms-2">{{ establishment.street }}, {{ establishment.city_or_town }}, {{
                            establishment.province }}</p>
                    </div>
                    <div class="d-flex">

                        <button class="btn btn-primary btn-sm me-2 fs-6 fw-light" @click="getDocuments"><span><i
                                    class="bi bi-file-earmark-text"></i></span>
                            View Documents</button>

                        <button v-if="establishment.is_verified" type="button" @click="verifyEstablishment(0)"
                            class="btn btn-outline-secondary btn-sm me-2"><span><i class="bi bi-patch-check"></i></span>
                            Unverify</button>

                    </div>
                </div>
                <div class="modal fade" ref="modalDocsRef" id="modalDocs" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">

                            <div class="modal-body text-center">
                                <p>Here are the presented documents by the business owner.</p>
                                <div v-if="isLoadingDocs"
                                    class="d-flex justify-content-center align-items-center bg-transparent">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="documents" class="d-flex flex-column">
                                        <div class="" v-for="document in documents">
                                            <a :href="document.file_url" target="_blank" rel="noopener noreferrer"
                                                type="button" class="btn btn-link">View
                                                File <span><i class="bi bi-box-arrow-up-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>No documents found.</p>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button @click="verifyEstablishment(1)" v-if="isDocuments && !establishment.is_verified"
                                    type="button" class="btn btn-outline-primary"><span v-if="isLoadingBtn"
                                        class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>Verify</button>
                                <button type="button" class="btn btn-secondary" @click="closeModalDocs">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1>Reservation Logs</h1>
                <div class="card p-4">
                    <reservation-table tblColumn="Reserve user from" :slug="$route.params.slug" :reserveLogs="reserveLogs"
                        :isUser="false" />
                </div>
            </div>

            <div ref="toastMessageRef" class="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="toastMessage" class="toast border-0 text-bg-success" role="alert" aria-live="assertive"
                    aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            <span><i class="bi bi-check-circle"></i></span>
                            {{ notificationMessage }}
                        </div>
                        <button type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="Close"
                            @click="closeToast"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { Modal, Toast } from 'bootstrap'

const route = useRoute()
const store = useStore()
const isLoading = ref(true)
const isLoadingBtn = ref(false)
const isLoadingDocs = ref(true)
const isDocuments = ref(false)

// let modal_docs = ref(null)
const modalDocsRef = ref(null)
// let modal_docs = ref(null)

const state = reactive({
    modal_docs: null,
    show_toast: null,
})


const establishment = computed(() => store.getters['main/getSelectedEstablishment'])
const reserveLogs = computed(() => store.getters['main/getReserveLogs'])
const documents = computed(() => store.getters['main/getDocuments'])
const notificationMessage = computed(() => store.getters['main/getNotificationMessage'])

async function getDocuments() {
    // state.modal_docs.show()

    openModalDocs()
    await store.dispatch('main/getDocuments', { 'slug': route.params.slug })

    if (documents.value.length > 0) {
        isDocuments.value = true
    }

    isLoadingDocs.value = false
}

async function verifyEstablishment(status) {
    isLoadingBtn.value = true
    await store.dispatch('main/verifyEstablishment', {
        'detail': {
            'id': establishment.value.id,
            'status': status,
        }
    })

    await store.dispatch('main/getSelectedEstablishment', { 'establishment_slug': route.params.slug })

    await store.dispatch('main/fetchReserveLogs', { 'from': 'establishments', 'slugs': route.params.slug })

    isLoadingBtn.value = false

    closeModalDocs()

    showToast()
}

onMounted(async () => {
    await store.dispatch('main/getSelectedEstablishment', { 'establishment_slug': route.params.slug })

    await store.dispatch('main/fetchReserveLogs', { 'from': 'establishments', 'slugs': route.params.slug })

    isLoading.value = false

    setTimeout(() => {
        state.modal_docs = new Modal(modalDocsRef.value, {
            keyboard: false,
            backdrop: "static",
        });


        state.show_toast = new Toast('#toastMessage')
    }, 1000)
})

function openModalDocs() {
    state.modal_docs.show()
}
function closeModalDocs() {
    state.modal_docs.hide()
}
function showToast() {
    state.show_toast.show()
}

function closeToast() {
    state.show_toast.hide()
}
</script>

<script>
import ReservationTable from "@/components/ReservationTable.vue"

export default {
    components: {
        ReservationTable,
    },
}
</script>
