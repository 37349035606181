<template>
    <div class="container vh-100">
        <div class="row h-100 w-100">
            <div class="col-12 col-md-8 m-auto shadow p-0">
                <div class="row align-items-center">
                    <div class="d-md-block d-none col-6 p-5" style="background-color: #fd754d;">
                        <div class="p-4">
                            <img src="../assets/svg/Reserba_white.svg" class="img-fluid mx-auto d-block">
                        </div>
                        <p class="text-white text-center mt-5 h3">Reserba</p>
                    </div>
                    <div class="col-12 col-md-6 p-5">
                        <form>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email address</label>
                                <input v-model="credentials.email" type="email" class="form-control" id="exampleInputEmail1"
                                    aria-describedby="emailHelp">
                                <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Password</label>
                                <div class="input-group">

                                    <input v-if="showPassword" v-model="credentials.password" type="text"
                                        class="form-control" id="exampleInputPassword1">
                                    <input v-else v-model="credentials.password" type="password" class="form-control"
                                        id="exampleInputPassword1">
                                    <button type="button" class="btn btn-outline-secondary" @click.prevent="toggleShow"><i
                                            class="bi"
                                            :class="{ 'bi-eye-slash-fill': showPassword, 'bi-eye-fill': !showPassword }"></i></button>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary text-white"
                                @click.prevent="startLogin">Login</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data: () => ({
        credentials: {
            email: '',
            password: '',
            role: 'Admin',
        },
        showPassword: false,
    }),
    methods: {
        ...mapActions('main', ['login']),
        startLogin() {
            console.log('starting login')
            this.login(this.credentials).then(() => this.$router.push({ path: '/' }))
                .catch(err => console.log(err))
        },
        toggleShow() {
            this.showPassword = !this.showPassword
        }
    }
}
</script>