
<template>
    <div class="py-3">
        <div class="container-fluid bg-white p-5 rounded table-responsive mh-100">
            <div class="container-fluid bg-light p-5 mb-5">
                <div class="row">
                    <div class="col">
                        <h1 class="display-5 fw-bold">Banned Users</h1>
                        <p class="col-md-8 fs-4">Lists of banned users</p>
                    </div>
                    <div class="col">
                        <img :src="mySVG" class="image-fluid" style="height: 200px;" />
                    </div>
                </div>
            </div>
            <table class="table table-hover caption-top">
                <!-- <caption>List of users {{  }}</caption> -->
                <caption>
                    <button v-if="isBan" type="button" class="btn btn-outline-warning" id="liveToastBtn"
                        @click.prevent="unselectAllBan">
                        Unselect all
                    </button>
                    <button v-else type="button" class="btn btn-outline-warning" id="liveToastBtn"
                        @click.prevent="showBanToast">
                        Unban User
                    </button>
                </caption>
                <thead class="table-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th v-if="isBan" scope="col">Ban</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in bannedUsers" :key="item.id">
                        <td class="text-secondary">{{ index +=1 }}</td>
                        <td class="text-secondary">{{ item.name }}</td>
                        <td class="text-secondary">{{ item.email }}</td>
                        <td class="text-secondary fs-6"><small><span class="badge rounded-pill"
                                    :class="roleColor(item.role)">{{
                                        item.role
                                    }}</span> </small>
                        </td>
                        <td class="text-secondary fs-6"><small><span class="badge text-bg-danger rounded-pill">{{
                            item.status_display
                        }}</span> </small>
                        </td>
                        <td v-if="isBan" class="text-warning">
                            <input class="form-check-input" type="checkbox" :value="item.id" v-model="bannedUser">
                        </td>
                    </tr>

                </tbody>
            </table>

            <div class="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
                <div id="banToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
                    data-bs-autohide="false">
                    <div class="toast-header">
                        <strong class="me-auto">Reserba</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                            @click.prevent="closeBanToast"></button>
                    </div>
                    <div class="toast-body">
                        Are you sure you want to ban selected user/s?
                        <div class="d-flex justify-content-around mt-4 pt-2 border-top">
                            <button type="button" class="btn btn-primary btn-sm" @click.prevent="confirmBanUsers">Ban
                                Users</button>
                            <button type="button" class="btn btn-secondary btn-sm"
                                @click.prevent="closeBanToast">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        <!-- <div class="d-flex justify-content-end">
                <Pagination :current-page="currentPage" :total-pages="totalPages" :fetch-function="fetchData" />
                                                                                                                                                                    </div> -->

            <div ref="toastMessageRef" class="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="toastMessage" class="toast border-0" :class="toastClass" role="alert" aria-live="assertive"
                    aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            {{ notificationMessage }}
                        </div>
                        <button type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="Close"
                            @click="closeToast"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from "vuex"
import { ref, unref, reactive, onMounted, computed, toRefs } from "vue"
import { Modal, Toast } from 'bootstrap'
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        Pagination,
    },
    data: () => ({
        role: '',
        showPassword: false,
        showConfirmPassword: false,
        mySVG: require('@/assets/svg/ban.svg')
    }),
    computed: {
    },
    methods: {
        roleColor(role) {
            if (role === 'Customer') {
                return 'text-bg-primary'
            } else {
                return 'text-bg-success'
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword
        },
        toggleShowConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword
        },
    },
    mounted() {
    },
    setup() {
        const store = useStore()
        const notificationMessage = computed(() => store.getters['main/notificationMessage'])
        const bannedUsers = computed(() => store.getters['main/bannedUsers'])
        let toastMesssage = ''
        let toastClass = 'text-bg-success'

        let bannedUser = ref([])
        let isBan = ref(false)

        const paginationMeta = computed(() => store.getters['main/getPaginationMeta'])
        const currentPage = computed(() => store.getters['main/getCurrentPage'])
        const totalPages = computed(() => store.getters['main/getTotalPages'])

        const state = reactive({
            userID: 0,
        })

        onMounted(() => {
            state.show_toast = new Toast('#toastMessage')
            state.show_ban_toast = new Toast('#banToast')
            store.dispatch('main/getBannedUsers');
        })

        function showToast() {
            state.show_toast.show()
        }

        function closeToast() {
            state.show_toast.hide()
        }

        function unselectAllBan() {
            bannedUser.value.splice(0)
        }

        function showBanToast() {
            isBan.value = true
            state.show_ban_toast.show()
        }

        function closeBanToast() {
            state.show_ban_toast.hide()
            isBan.value = false
        }

        async function confirmBanUsers() {
            await store.dispatch('main/banUsers', { 'bannedUsers': bannedUser, 'status': 1 })
            await store.dispatch('main/getBannedUsers');
        }


        return {
            notificationMessage,
            currentPage,
            totalPages,
            toastMesssage,
            bannedUsers,

            toastClass,
            bannedUser,
            isBan,

            showToast,
            closeToast,
            showBanToast,
            closeBanToast,
            unselectAllBan,
            confirmBanUsers,
        }
    },
}
</script>