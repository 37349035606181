import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import Login from '../views/Login.vue'
import EstablishmentLists from '../views/Dashboard/EstablishmentLists.vue'
import EstablishmentView from '@/views/Dashboard/EstablishmentView.vue'
import UserLists from '../views/Dashboard/UserLists.vue'
import BanUser from '../views/Dashboard/BannedUser.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import UserDetails from '../views/Dashboard/UserDetails.vue'
import NotFound from '../views/NotFound.vue'
import Verifications from '../views/Dashboard/Verifications.vue'
import Confirmations from '../views/Dashboard/Confirmations.vue'
import Messages from '../views/Messages.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'main',
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/establishments',
        name: 'establishments',
        component: EstablishmentLists,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/establishmentview/:slug',
        name: 'establishmentsView',
        component: EstablishmentView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/users',
        name: 'users',
        component: UserLists,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banuser',
        name: 'banUser',
        component: BanUser,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/userdetails',
        name: 'userDetails',
        component: UserDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/verifications',
        name: 'verifications',
        component: Verifications,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/confirmations',
        name: 'confirmations',
        component: Confirmations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/messages',
        name: 'messages',
        component: Messages,
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/:matchPath(.*)*',
    name: 'NotFound',
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Use the page's router title to name the page
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  store.dispatch('main/getToken')
  const token = store.getters['main/personalToken']

  if (to.meta.requiresAuth) {
    if (token) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
