import axios from "axios"
import VueCookies from "vue-cookies"

const state = {
  token: "",
  errors: {},
  authenticated: false,
  userData: {},
  userDetail: {},
  establishmentLists: [],
  userLists: [],
  usersCount: 0,
  notificationMessage: "",
  bannedUsers: [],
  monthlyUserGrowth: {},
  paginationMeta: {},
  currentPage: 1,
  totalPages: 1,
  reserveLogs: {},
  selectedEstablishment: {},
  selectedUser: {},
  documents: {},
  verificationLists: {},
  confirmationLists: {},
  allMessages: {},
  conversationMessage: {},
};

const getters = {
  personalToken: (state) => state.token,
  isAuthenticated: (state) => state.authenticated,
  userData: (state) => state.userData,
  userDetail: (state) => state.userDetail,
  establishmentLists: (state) => state.establishmentLists,
  userLists: (state) => state.userLists,
  usersCount: (state) => state.usersCount,
  getNotificationMessage: (state) => state.notificationMessage,
  bannedUsers: (state) => state.bannedUsers,
  monthlyUserGrowth: (state) => state.monthlyUserGrowth,
  getPaginationMeta: (state) => state.paginationMeta,
  getCurrentPage: (state) => state.paginationMeta.current_page,
  getTotalPages: (state) => {
    return Math.ceil(state.paginationMeta.total / state.paginationMeta.per_page)
  },
  getReserveLogs: (state) => state.reserveLogs,
  getSelectedEstablishment: (state) => state.selectedEstablishment,
  getDocuments: (state) => state.documents,
  getVerificationLists: (state) => state.verificationLists,
  getConfirmationLists: (state) => state.confirmationLists,
  getAllMessages: (state) => state.allMessages,
  getAllMessages: (state) => state.allMessages,
  getConversationMessage: (state) => state.conversationMessage,
};

const actions = {
  saveToken({ commit }, token) {
    $cookies.set("token", token, "1d");
    commit("SAVE_TOKEN", token);
    commit("CHANGE_AUTH", true);
  },
  saveUserData({ commit }, userData) {
    commit("SAVE_USERDATA", userData);
  },
  deleteToken({ commit }) {
    $cookies.remove("token");
    commit("REMOVE_TOKEN");
    commit("CHANGE_AUTH", false);
  },
  getToken({ commit }) {
    var token = $cookies.get("token");
    commit("SAVE_TOKEN", token);
    if (token) {
      commit("CHANGE_AUTH", true);
    }
  },
  saveAuth({ commit }, credentials) {
    const token = $cookies.get("token");
    commit("SAVE_TOKEN", token);
    commit("CHANGE_AUTH", credentials.isAuthenticated);
  },
  saveEstablishments({ commit }, establishmentLists) {
    commit("SAVE_ESTABLISHMENTS", establishmentLists);
  },
  saveSelectedEstablishment({ commit }, selectedEstablishment) {
    commit("SAVE_SELECTED_ESTABLISHMENT", selectedEstablishment);
  },
  saveSelectedUser({ commit }, selectedUser) {
    commit("SAVE_SELECTED_USER", selectedUser);
  },
  saveUserLists({ commit }, userLists) {
    commit("SAVE_USERLISTS", userLists);
  },
  saveUsersCount({ commit }, usersCount) {
    commit("SAVE_USERSCOUNT", usersCount);
  },
  saveDocuments({ commit }, documents) {
    commit("SAVE_DOCUMENTS", documents);
  },
  saveNotificationMessage({ commit }, notificationMessage) {
    commit("SAVE_NOTIFICATION_MESSAGE", notificationMessage);
  },
  saveVerificationLists({ commit }, verificationLists) {
    commit("SAVE_VERIFICATION_LISTS", verificationLists);
  },
  saveConfirmationLists({ commit }, confirmationLists) {
    commit("SAVE_CONFIRMATION_LISTS", confirmationLists);
  },
  saveAllMessages({ commit }, allMessages) {
    commit("SAVE_ALL_MESSAGES", allMessages);
  },
  saveConversationMessage({ commit }, conversationMessage) {
    commit("SAVE_CONVERSATION_MESSAGE", conversationMessage);
  },
  // saveMessengerNames({ commit }, messengerNames) {
  //   commit("SAVE_MESSENGER_NAMES", messengerNames);
  // },
  async checkAuthentication({ commit, dispatch }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.post("token-check", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const authentication = {
        isAuthenticated: response.data["authenticated"],
      };

      if (authentication.isAuthenticated) {
        dispatch("saveAuth", authentication);
      } else {
        commit("REMOVE_TOKEN", false);
        commit("CHANGE_AUTH", false);
      }
    } catch (error) {
      commit("CHANGE_AUTH", false);
    }
  },
  async login({ commit, dispatch }, credentials) {
    try {
      const response = await axios.post(
        "https://api.reserba.com.ph/api/v1/login",
        credentials
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        // console.log(response.data);
        dispatch("saveToken", response.data.message["token"]);
        dispatch("saveUserData", response.data["user"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async logout({ commit, dispatch }, credentials) {
    const token = $cookies.get("token");
    try {
      const response = await axios.post(
        "https://api.reserba.com.ph/api/v1/auth/logout",
      );
      $cookies.remove("token")
      commit("REMOVE_TOKEN")
    } catch (error) {
      $cookies.remove("token")
      commit("REMOVE_TOKEN")
      // console.log(`no commit errors = ${error}`);
    }
  },
  async registerUser({ commit, dispatch }, userDetails) {
    const token = $cookies.get("token");
    let role = userDetails.userDetails.role.toLowerCase()
    console.log('token: ', token)
    console.log('userDetails: ', userDetails.userDetails)
    try {
      const response = await axios.post(
        `https://api.reserba.com.ph/api/v1/register/${role}`,
        {
          name: userDetails.userDetails.name,
          email: userDetails.userDetails.email,
          password: userDetails.userDetails.password,
          password_confirmation: userDetails.userDetails.confirmPassword
        },
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_NOTIFICATION_MESSAGE", response.data.message);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async updateUser({ commit, dispatch }, userDetails) {
    const token = $cookies.get("token");
    let role = userDetails.userDetails.role.toLowerCase()
    try {
      const response = await axios.patch(
        `https://api.reserba.com.ph/api/v1/auth/user-update`,
        {
          id: userDetails.userDetails.id,
          name: userDetails.userDetails.name,
          email: userDetails.userDetails.email,
          password: userDetails.userDetails.password,
          password_confirmation: userDetails.userDetails.confirmPassword
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",

          }
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_NOTIFICATION_MESSAGE", response.data.message);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async deleteUser({ commit, dispatch, getters }, id) {
    const token = $cookies.get("token");
    const userId = id.id
    console.log('token: ', token)
    try {
      const response = await axios.delete(
        `https://api.reserba.com.ph/api/v1/auth/admin/users/delete/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",

          }
        }
      );
      if (response.data["error"]) {
        // commit("ERRORS", "invalid credentials");
        console.log('user deleted')
      } else {
        getters.userLists
        commit("SAVE_NOTIFICATION_MESSAGE", response.data.message);
        dispatch('updatedUserLists', userId)
      }
    }
    catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async banUsers({ commit, dispatch, getters }, { bannedUsers, status }) {
    const token = $cookies.get("token");
    console.log('token: ', token)
    console.log(bannedUsers.value)
    console.log(status)
    try {
      const response = await axios.post(
        `https://api.reserba.com.ph/api/v1/auth/admin/users/account-ban`,
        {
          users: bannedUsers.value,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      if (response.data["error"]) {
        console.log('user banned')
      } else {
        console.log(response.data.message)
        commit("SAVE_NOTIFICATION_MESSAGE", response.data.message);
      }
    }
    catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async updatedUserLists({ getters, dispatch }, userId) {
    const newItem = getters.userLists.filter(item => item.id !== userId);

    dispatch("saveUserLists", newItem);
  },
  async verifyEstablishment({ commit, dispatch }, detail) {
    const token = $cookies.get("token");
    try {
      const response = await axios.patch(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/verification-update`,
        {
          id: detail.detail.id,
          status: detail.detail.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        },
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        console.log(response.data);
        // dispatch("saveToken", response.data.message["token"]);
        // dispatch("saveUserData", response.data["user"]);
        dispatch("saveNotificationMessage", response.data["message"])
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async approveEstablishment({ commit, dispatch }, detail) {
    const token = $cookies.get("token");
    try {
      const response = await axios.patch(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/status-update`,
        {
          id: detail.detail.id,
          status: detail.detail.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        },
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        // dispatch("saveToken", response.data.message["token"]);
        // dispatch("saveUserData", response.data["user"]);
        dispatch("saveNotificationMessage", response.data["message"])
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async sendMessage({ commit, dispatch }, { message, type, slug }) {
    const token = $cookies.get("token");
    console.log('token: ', token)
    console.log('message: ', message.body)
    console.log('type: ', type)
    console.log('slug: ', slug)
    try {
      const response = await axios.post(
        `https://api.reserba.com.ph/api/v1/auth/messages/contact-${type}/${slug}`,
        {
          header: 'Header',
          body: message.body,
          // images: '',
          // category_id: '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        },
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_NOTIFICATION_MESSAGE", response.data.message);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getUserDetails({ commit, dispatch }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        "https://api.reserba.com.ph/api/v1/auth/user-details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_USERDETAIL", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getEstablishmentLists({ commit, dispatch, state }, { searchText, pageNumber, column, order }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/list?search=${searchText}&page=${pageNumber}&column=${column}&order=${order}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_PAGINATION_META", response.data["meta"]);
        dispatch("saveEstablishments", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getSelectedEstablishment({ state, commit, dispatch }, { establishment_slug }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/details/${establishment_slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        dispatch("saveSelectedEstablishment", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getSelectedUser({ commit, dispatch }, { user_slug }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/users/details/${user_slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        dispatch("saveSelectedUser", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getUserLists({ commit, dispatch }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        "https://api.reserba.com.ph/api/v1/auth/admin/users/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        // console.log('currentPage: ', response.data["meta"].current_page);
        commit("ERRORS", "invalid credentials");
        commit("SAVE_PAGINATION_META", response.data["meta"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getPaginatedUserLists({ commit, dispatch }, { searchText, pageNumber, column, order }) {
    try {
      const token = $cookies.get("token");

      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/users/list?search=${searchText}&page=${pageNumber}&column=${column}&order=${order}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        // console.log('currentPage: ', response.data["meta"].current_page);
        commit("SAVE_PAGINATION_META", response.data["meta"]);
        dispatch("saveUserLists", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getBannedUsers({ commit, dispatch }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        "https://api.reserba.com.ph/api/v1/auth/admin/customer-bans/full-list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_BANNED_USERS", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getUsersCount({ commit, dispatch }) {
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        "https://api.reserba.com.ph/api/v1/auth/admin/stats/users",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        dispatch("saveUsersCount", response.data);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getMonthlyUserGrowth({ commit, dispatch, state }) {
    console.log('get monthly')
    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        "https://api.reserba.com.ph/api/v1/auth/admin/stats/registrations/monthly",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        commit("SAVE_MONTHLY_USER_GROWTH", response.data);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async fetchReserveLogs({ commit, dispatch }, { from, slugs }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/${from}/reservation-logs/${slugs}?limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data["error"]) {
        commit("ERRORS", "invalid credentials");
      } else {
        // console.log('response_data: ', response.data["data"]);
        commit("SAVE_RESERVE_LOGS", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async getDocuments({ commit, dispatch }, { slug }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/documents/${slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response["error"]) {
        // commit("ERRORS", "invalid credentials");
        commit("SAVE_DOCUMENTS", response.data["data"]);
      } else {
        // console.log('response_data: ', response.data["data"]);
        commit("SAVE_DOCUMENTS", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async fetchVerificationLists({ commit, dispatch }, { name, status, is_verified }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/search/verification-list?name=${name}&status=${2}&is_verified=${0}&has_documents=${1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response["error"]) {
        commit("ERRORS", "invalid credentials");
        console.log('error')
        // commit("SAVE_DOCUMENTS", response.data["data"]);
      } else {
        commit("SAVE_VERIFICATION_LISTS", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async fetchConfirmationLists({ commit, dispatch }, { name, status }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/establishments/search/confirmation-list?status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response["error"]) {
        commit("ERRORS", "invalid credentials");
        // commit("SAVE_DOCUMENTS", response.data["data"]);
      } else {
        // console.log('response_data: ', response.data["data"]);
        commit("SAVE_CONFIRMATION_LISTS", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async fetchAllMessages({ commit, dispatch }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/admin/messages/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response["error"]) {
        commit("ERRORS", "invalid credentials");
        // commit("SAVE_DOCUMENTS", response.data["data"]);
      } else {
        commit("SAVE_ALL_MESSAGES", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
  async fetchConversationMessage({ commit, dispatch }, { slug }) {

    try {
      const token = $cookies.get("token");
      const response = await axios.get(
        `https://api.reserba.com.ph/api/v1/auth/messages/conversation/${slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response["error"]) {
        commit("ERRORS", "invalid credentials");
        // commit("SAVE_DOCUMENTS", response.data["data"]);
      } else {
        commit("SAVE_CONVERSATION_MESSAGE", response.data["data"]);
      }
    } catch (error) {
      console.log(`no commit errors = ${error}`);
    }
  },
};

const mutations = {
  ERRORS: (state, error) => {
    console.log(`errors = ${error}`);
    state.errors = error;
  },
  SAVE_TOKEN: (state, token) => {
    state.token = token;
  },
  SAVE_USERDATA: (state, userData) => {
    state.userData = userData;
  },
  SAVE_USERDETAIL: (state, userDetail) => {
    state.userDetail = userDetail;
  },
  REMOVE_TOKEN: (state) => {
    state.token = "";
  },
  CHANGE_AUTH: (state, authentication) => {
    state.authenticated = authentication;
  },
  SAVE_ESTABLISHMENTS: (state, establishmentLists) => {
    state.establishmentLists = establishmentLists
  },
  SAVE_SELECTED_ESTABLISHMENT: (state, selectedEstablishment) => {
    state.selectedEstablishment = selectedEstablishment
  },
  SAVE_SELECTED_USER: (state, selectedUser) => {
    state.selectedUser = selectedUser
  },
  SAVE_USERLISTS: (state, userLists) => {
    state.userLists = userLists
  },
  SAVE_USERSCOUNT: (state, usersCount) => {
    state.usersCount = usersCount
  },
  SAVE_NOTIFICATION_MESSAGE: (state, notificationMessage) => {
    state.notificationMessage = notificationMessage
  },
  SAVE_BANNED_USERS: (state, bannedUsers) => {
    state.bannedUsers = bannedUsers
  },
  SAVE_MONTHLY_USER_GROWTH: (state, monthlyUserGrowth) => {
    state.monthlyUserGrowth = monthlyUserGrowth
  },
  SAVE_PAGINATION_META: (state, paginationMeta) => {
    state.paginationMeta = paginationMeta
  },
  SAVE_RESERVE_LOGS: (state, reserveLogs) => {
    state.reserveLogs = reserveLogs
  },
  SAVE_DOCUMENTS: (state, documents) => {
    state.documents = documents
  },
  SAVE_VERIFICATION_LISTS: (state, verificationLists) => {
    state.verificationLists = verificationLists
  },
  SAVE_CONFIRMATION_LISTS: (state, confirmationLists) => {
    state.confirmationLists = confirmationLists
  },
  SAVE_ALL_MESSAGES: (state, allMessages) => {
    state.allMessages = allMessages
  },
  SAVE_CONVERSATION_MESSAGE: (state, conversationMessage) => {
    state.conversationMessage = conversationMessage
  },
  // SAVE_MESSENGER_NAMES: (state, messengerNames) => {
  //   state.messengerNames = messengerNames
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
