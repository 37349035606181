<template>
    <div class="py-3">
        <div class="container-fluid bg-light p-5">
            <h4>Reserba <span class="fw-bold">Dashboard</span></h4>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="row row-cols-1 row-cols-md-2 g-4">
                        <div v-for="(item, index) in basicStats" :key="item.id" class="col">
                            <div class="card border-light">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col mt-0">
                                            <h5 class="card-title text-secondary">{{ item.title }}</h5>
                                        </div>

                                        <div class="col-auto">
                                            <div class="stat text-primary fs-4">
                                                <i :class="icons[index++]"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 class="mt-1 mb-3 fw-normal">{{ item.value }}</h1>
                                    <div class="mb-0">
                                        <span :class="isPositive(item.increase)"> <i class="mdi mdi-arrow-bottom-right"></i>
                                            {{
                                                item.increase }}% </span>
                                        <span class="text-muted">{{ item.time }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <!-- <UserGrowthChart /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import UserGrowthChart from '@/components/UserGrowthChart.vue'

export default {
    components: {
        UserGrowthChart
    },
    data: () => ({
        icons: [
            'bi bi-calendar-event',
            'bi bi-currency-dollar',
            'bi bi-people-fill',
            'bi bi-currency-dollar',
        ],
        basicStats: [
            {
                id: 1,
                title: 'Appointments',
                value: 2.2382,
                increase: -3.65,
                time: 'Since last week'
            },
            {
                id: 2,
                title: 'Earnings',
                value: '$1,000',
                increase: +3.65,
                time: 'Since last week'
            },
            {
                id: 3,
                title: 'Users',
                value: '220',
                increase: +5,
                time: 'Since last week'
            },
            {
                id: 4,
                title: 'Earnings',
                value: '$1,000',
                increase: +3.65,
                time: 'Since last week'
            },
        ]
    }),
    computed: {
        ...mapGetters('main', ['usersCount'])
    },
    methods: {
        ...mapActions('main', ['getUsersCount']),
        isPositive(value) {
            if (value > 0) {
                return 'text-success'
            } else {
                return 'text-danger'
            }
        }
    },
    async mounted() {
        await this.getUsersCount()
        this.basicStats[2].value = this.usersCount.total_users
    }
}
</script>