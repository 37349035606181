<template>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">{{ tblColumn }}</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in reserveLogs" :key="item.id">
                <td>{{ item.date_start }}</td>
                <td v-if="isUser">{{ item.establishment.name }}</td>
                <td v-else>{{ item.user.name }}</td>
                <td><small><span class="" :class="statusColor(item.display_status)">{{
                    item.display_status
                }}</span> </small></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { onMounted } from "vue";
import { mapGetters } from "vuex"
export default {
    props: {
        tblColumn: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        reserveLogs: {
            type: Array,
            default: null,
            required: true,
        },
        isUser: {
            type: Boolean,
            required: true,
        }
        // fetchReserveLogs: {
        //     type: Function,
        //     required: true
        // }
    },
    methods: {
        statusColor(status) {
            if (status === 'pending') {
                return 'text-danger'
            } else {
                return 'text-success'
            }
        },
    },
    // mounted() {
    //     this.isUser = null
    // }
}
</script>