<template>
    <nav>
        <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" @click.prevent="goToPage(1)">First</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" @click.prevent="goToPage(currentPage - 1)">Previous</a>
            </li>
            <li class="page-item" v-for="pageNumber in pageNumbers" :key="pageNumber"
                :class="{ active: currentPage === pageNumber }">
                <a class="page-link" @click.prevent="goToPage(pageNumber)">{{ pageNumber }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" @click.prevent="goToPage(currentPage + 1)">Next</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" @click.prevent="goToPage(totalPages)">Last</a>
            </li>
        </ul>
    </nav>
</template>
  
<script>
// import axios from 'axios';

export default {
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    computed: {
        pageNumbers() {
            const pageNumbers = [];
            for (let i = this.currentPage; i <= this.getPages; i++) {
                pageNumbers.push(i);
            }
            // for (let i = this.currentPage; i <= (this.currentPage + 2); i++) {
            //     pageNumbers.push(i);
            // }
            return pageNumbers;
        },
        getPages() {
            const total = this.currentPage + 2
            if (total <= this.totalPages) {
                return total
            } else {
                return this.currentPage
            }
        }
    },
    methods: {
        async goToPage(pageNumber) {
            if (pageNumber < 1 || pageNumber > this.totalPages) {
                return;
            }
            this.$emit('current-page', pageNumber)

            await this.fetchFunction(pageNumber);
        },
    },
};
</script>
  