
<template>
    <div class="py-3 mh-100 overflow-hidden">
        <!-- <div class="container-fluid bg-light p-5 mb-5">
            <div class="row">
                <div class="col">
                    <h1 class="display-5 fw-bold">View Establishments</h1>
                    <p class="col-md-8 fs-4">Lists of all establishments</p>
                </div>
                <div class="col">
                    <img :src="mySVG" class="image-fluid" style="height: 200px;" />
                </div>
            </div>
        </div> -->
        <div class="container-fluid bg-white p-5 rounded table-responsive mb-5">
            <div class="row">
                <div class="col-4 border-end">
                    <h4 class="mb-3">Chats</h4>
                    <input class="mb-3 form-control" type="text" placeholder="Search..." aria-label="default input example">
                    <button type="button" class="mb-4 btn btn-outline-primary" @click.prevent="getMessages"
                        :class="{ 'disabled': isLoadingContacts }">
                        <span v-if="!isLoadingContacts">Check for new messages </span>
                        <span v-if="!isLoadingContacts"><i class="bi bi-arrow-clockwise"></i></span>

                        <span v-if="isLoadingContacts" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>

                    <ul class="list-group list-group-flush overflow-scroll pb-2 " style="height: 75vh;">
                        <button v-for="(message, index) in allMessages" class="list-group-item px-0"
                            :class="{ isActive: active === index }"
                            @click="updateActive(index, message.sender.name, message.conversation_id, message.sender.type, message.sender.slug)">

                            <div class="d-flex align-items-center">
                                <div class="circle">
                                    <p class="circle-inner ">{{ getFirstLetters(message.sender.name) }}</p>
                                </div>
                                <div class="text-start">
                                    <p class="fs-6 mb-0">{{ message.sender.name }}</p>
                                    <p class="fw-light fs-6 mb-0 text-muted">{{ message.length }} messages</p>
                                </div>
                            </div>
                        </button>
                    </ul>
                </div>
                <div v-if="isConvoSelected" class="col-8 ">
                    <div class="border-bottom mb-2">
                        <div class="d-flex align-items-center">
                            <div class="circle">
                                <p class="circle-inner ">{{ getFirstLetters(selectedContact.selectedPerson) }}</p>
                            </div>
                            <div>
                                <p class="fs-5 mb-0">{{ selectedContact.selectedPerson }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="container pb-2 overflow-scroll" style="height: 65vh;">


                        <div v-if="isLoadingMessages" class="d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else v-for="message in conversationMessage">

                            <div v-if="message.sender.name === selectedContact.selectedPerson">

                                <div class="d-inline-flex">
                                    <p class="small mb-1 me-2">{{ selectedContact.selectedPerson }}</p>
                                    <p class="small mb-1 text-muted fw-lighter">
                                        <date-format :date="message.date_sent" :has-time="true" :short-month="true" />
                                    </p>
                                </div>
                                <div class="d-flex flex-row justify-content-start">
                                    <div class="circle">
                                        <p class="circle-inner ">{{ getFirstLetters(selectedContact.selectedPerson) }}</p>
                                    </div>
                                    <div>
                                        <p class="small p-2 ms-1 mb-3 rounded-3" style="background-color: #f5f6f7;">{{
                                            message.body }}</p>
                                    </div>
                                </div>
                            </div>

                            <div v-else>

                                <div class="d-flex flex-row-reverse">
                                    <p class="small mb-1 text-muted fw-lighter">
                                        <date-format :date="message.date_sent" :has-time="true" :short-month="true" />
                                    </p>
                                </div>
                                <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                                    <div>
                                        <p class="small p-2 mb-3 text-white rounded-3 bg-warning">{{ message.body }}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="d-inline-flex w-100 align-items-center mt-4">
                        <div class="w-100">
                            <textarea v-model="myMessage.body" class="form-control" id="exampleFormControlTextarea1"
                                rows="3" placeholder="Write a message..."></textarea>
                        </div>
                        <button class="btn" @click="sendMessage"><i class="bi bi-send text-primary fs-3"></i></button>
                    </div>
                </div>
                <div v-else class="col-8 text-center">
                    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                        <img :src="mySVG" class="image-fluid mb-4" style="height: 200px;" />
                        <p class="text-muted">Please select a conversation...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.circle {
    display: inline-block;
    background-color: #FF9400;
    margin: 10px;
    border-radius: 50%;
}

.circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 3.5rem;
    width: 3.5rem;
    /* font-size: 30px; */
}

.isActive {
    background-color: #fffaf3;
}

.list-group-item:hover {
    background-color: #fffaf3;
}
</style>

<script>
export default {
    data: () => ({
        mySVG: require('@/assets/svg/notify.svg')
    }),
}
</script>

<script setup>

import { ref, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore()

// const isActive = ref(null)
const active = ref(-1)
const isConvoSelected = ref(false)
// const selectedPerson = ref('')
const isLoadingMessages = ref(true)
const isLoadingContacts = ref(false)
const selectedContact = reactive({
    selectedPerson: '',
    slug: '',
    type: '',
    conversation_id: '',
})
const myMessage = reactive({
    body: '',
})

const allMessages = computed(() => store.getters['main/getAllMessages'])
const conversationMessage = computed(() => store.getters['main/getConversationMessage'])

onMounted(async () => {
    await store.dispatch('main/fetchAllMessages')
})

function getFirstLetters(name) {
    const words = name.split(" ");
    return words.map(word => word.charAt(0)).join("");
}

async function updateActive(val, name, conversation_id, type, slug) {
    active.value = val
    isConvoSelected.value = true
    selectedContact.selectedPerson = name
    selectedContact.type = type
    selectedContact.slug = slug
    selectedContact.conversation_id = conversation_id
    isLoadingMessages.value = true

    await store.dispatch('main/fetchConversationMessage', { 'slug': selectedContact.conversation_id })

    isLoadingMessages.value = false
}

async function getMessages() {
    isLoadingContacts.value = true

    await store.dispatch('main/fetchAllMessages')

    isLoadingContacts.value = false
}

async function sendMessage() {
    await store.dispatch('main/sendMessage', { 'message': myMessage, 'type': selectedContact.type, 'slug': selectedContact.slug })

    myMessage.body = ''

    isLoadingMessages.value = true

    await store.dispatch('main/fetchConversationMessage', { 'slug': selectedContact.conversation_id })

    isLoadingMessages.value = false
}
</script>
