import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/assets/scss/custom.scss"
import DateFormat from '@voidsolutions/vue-dateformat'

createApp(App).use(store).use(router).use(DateFormat).mount('#app')
