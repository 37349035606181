
<template>
    <div class="py-3">
        <div class="container-fluid bg-white p-5 rounded table-responsive mh-100">
            <div class="container-fluid bg-light p-5 mb-5">
                <div class="row">
                    <div class="col">
                        <h1 class="display-5 fw-bold">View Establishments</h1>
                        <p class="col-md-8 fs-4">Lists of all establishments</p>
                    </div>
                    <div class="col">
                        <img :src="mySVG" class="image-fluid" style="height: 200px;" />
                    </div>
                </div>
            </div>
            <div class="row mb-5 ">
                <div class="col-4">
                    <div class="card-body p-3 text-bg-dark rounded">
                        <div class="row">
                            <div class="col-8 text-start">
                                <div class="shadow border-radius-md">
                                    <i class="bi bi-people-fill"></i>
                                </div>
                                <h5 class="text-white font-weight-bolder mb-0 mt-3">
                                    {{ usersCount.total_partners }}
                                </h5>
                                <span class="text-white text-sm">Establishments</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table class="table table-hover caption-top">
                <caption>
                    <div class="row align-items-center justify-content-between g-3 mb-4">
                        <div class="col col-auto col-lg-4">
                            <div class="input-group ">
                                <span class="input-group-text bg-white text-dark" id="basic-addon1">
                                    <i class="bi bi-search"></i>
                                </span>
                                <input v-model="searchText" type="text" class="form-control" placeholder="Search..."
                                    @change="searchLists(searchText)">
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="d-flex align-items-center">
                                <button type="button" class="btn btn-primary text-white me-3 btn-sm" id="liveToastBtn"
                                    @click="openModalForm(false)">
                                    Add User
                                </button>

                            </div>
                        </div>
                    </div>
                </caption>


                <thead class="table-light">
                    <tr>
                        <th scope="col">
                            <input class="form-check-input" type="checkbox" id="allLists" ref="allLists"
                                v-model="allSelected" @click="selectAll">
                        </th>
                        <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Name
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'nameDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('name', false, 'desc', searchText); setNameActive('nameDesc')"></i>
                                    <i :class="(isActiveHeader === 'nameAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill fs-6"
                                        @click="sortLists('name', true, 'asc', searchText); setNameActive('nameAsc')"></i>
                                </div>
                            </div>
                        </th>
                        <th scope="col">Email</th>
                        <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Province
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'provinceDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('province', !isAscName, 'desc', searchText); setNameActive('provinceDesc')"></i>
                                    <i :class="(isActiveHeader === 'provinceAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill"
                                        @click="sortLists('province', isAscName, 'asc', searchText); setNameActive('provinceAsc')"></i>

                                </div>
                            </div>
                        </th>
                        <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    City/Town
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'townDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('city_or_town', !isAscName, 'desc', searchText); setNameActive('townDesc')"></i>
                                    <i :class="(isActiveHeader === 'townAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill"
                                        @click="sortLists('city_or_town', isAscName, 'asc', searchText); setNameActive('townAsc')"></i>

                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Status
                                </div>
                            </div>
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in establishmentLists" :key="item.id">

                        <td class="text-warning">
                            <input class="form-check-input" type="checkbox" :value="item.id" v-model="bannedUser">
                        </td>
                        <td class="text-secondary">
                            <h6 class="fw-semibold fs-6">
                                {{ item.name }} <span><i class="bi ms-1 text-primary fs-6"
                                        :class="item.is_verified ? 'bi-patch-check-fill' : 'bi-patch-check'"></i></span>
                            </h6>
                        </td>
                        <td class="text-secondary">{{ item.user.email }}</td>
                        <td class="text-secondary">{{ item.province }}</td>
                        <td class="text-secondary">{{ item.city_or_town }}</td>
                        <td class="text-uppercase fs-6">
                            <p :class="statusColor(item.status_display)">{{ item.status_display }}</p>
                        </td>
                        <!-- <td class="text-secondary fs-6"><small><span class="badge rounded-pill fw-normal"
                                    :class="statusColor(item.status_display)">{{
                                        item.status_display
                                    }}</span>
                            </small>
                        </td> -->
                        <td class="text-danger">
                            <div class="dropdown-center">
                                <button class="btn btn-sm btn-light" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                </button>
                                <ul class="dropdown-menu py-2">
                                    <li>
                                        <!-- <a class="fs-6 fw-light dropdown-item" href="#" data-bs-toggle="modal"
                                            data-bs-target="#moreUserInfo" @click="getMoreDetails(item)">View</a> -->
                                        <router-link :to="`/establishmentview/${item.slug}`" class="fw-light dropdown-item">
                                            <span class="d-none d-sm-inline">View</span></router-link>
                                    </li>
                                    <li><a class="fs-6 fw-light dropdown-item" href="#">Ban</a></li>

                                    <li v-if="item.status_display === 'pending' && item.status_display !== 'banned'"><a
                                            class="fs-6 fw-light dropdown-item text-success"
                                            @click="openConfirmModal(item.id)">Confirm <i
                                                class="bi bi-check-circle mx-1"></i><span></span></a>
                                    </li>
                                    <hr class="dropdown-divider">
                                    <li><a class="fs-6 fw-light dropdown-item text-danger"
                                            @click="openDeleteModal(item.id)">Delete <i
                                                class="bi bi-trash mx-1"></i><span></span></a>
                                    </li>
                                </ul>
                            </div>
                        </td>

                        <div class="modal fade" id="moreUserInfo" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                <div class="modal-content">

                                    <div class="modal-body text-center">
                                        <div class="row justify-content-center mb-2">
                                            <div class="col-auto">
                                                <div class="bg-primary rounded-circle">
                                                    <p class="fs-3 text-white p-3">{{ nameInitial }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="fs-5 fw-semibold">{{ selectedUser.name }}</p>
                                        <p class="fs-6 fw-normal text-primary">{{ email }}</p>

                                        <p class="fs-6 fw-normal text-primary">{{ selectedUser.city_or_town }} {{
                                            selectedUser.province }}</p>
                                        <hr class="my-4">
                                        <reservation-table :tblColumn="tbleColumnName" :slug="selectedUser.slug"
                                            :reserveLogs="reserveLogs" :isUser="false" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                    <div class="modal fade" ref="modalConfirmRef" id="modalConfirm" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="modal_confirm_label">Business Confirmation</h1>
                                    <button type="button" class="btn-close" aria-label="Close"
                                        @click="closeConfirmModal"></button>
                                </div>
                                <div class="modal-body">
                                    Confirm registration for this business?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="closeConfirmModal">Close</button>
                                    <button type="button" class="btn btn-primary"
                                        @click.prevent="confirmBusiness()">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" ref="modalDeleteRef" id="modalDelete" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="modal_delete_label">Delete Business</h1>
                                    <button type="button" class="btn-close" aria-label="Close"
                                        @click="closeDeleteModal"></button>
                                </div>
                                <div class="modal-body">
                                    Are you sure you want to delete this user?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="closeDeleteModal">Close</button>
                                    <button type="button" class="btn btn-danger"
                                        @click.prevent="confirmDelete()">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </tbody>
            </table>

            <div class="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
                <div id="banToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
                    data-bs-autohide="false">
                    <div class="toast-header">
                        <strong class="me-auto">Reserba</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                            @click.prevent="closeBanToast"></button>
                    </div>
                    <div class="toast-body">
                        Are you sure you want to ban ({{ bannedUser.length }}) user/s?
                        <div class="d-flex justify-content-around mt-4 pt-2 border-top">
                            <button type="button" class="btn btn-primary btn-sm" @click.prevent="confirmBanUsers">Ban
                                Users</button>
                            <button type="button" class="btn btn-secondary btn-sm"
                                @click.prevent="closeBanToast">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <Pagination :current-page="currentPage" :total-pages="totalPages" :fetch-function="fetchData" />
            </div>

            <div ref="toastMessageRef" class="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="toastMessage" class="toast border-0" :class="toastClass" role="alert" aria-live="assertive"
                    aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            {{ notificationMessage }}
                        </div>
                        <button type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="Close"
                            @click="closeToast"></button>
                    </div>
                </div>
            </div>

            <div class="modal fade" ref="modalForm" id="modalForm" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="modal_delete_label">Modal title</h1>
                            <button type="button" class="btn-close" aria-label="Close" @click="closeModalForm"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input v-model="userDetails.name" type="text" class="form-control" id="name"
                                        aria-describedby="emailHelp">
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email address</label>
                                    <input v-model="userDetails.email" type="email" class="form-control" id="email"
                                        aria-describedby="emailHelp">
                                </div>
                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <div class="input-group">
                                        <input v-if="showPassword" v-model="userDetails.password" type="text"
                                            class="form-control" id="password">
                                        <input v-else v-model="userDetails.password" type="password" class="form-control"
                                            id="password">
                                        <button type="button" class="btn btn-outline-secondary"
                                            @click.prevent="toggleShow"><i class="bi"
                                                :class="{ 'bi-eye-slash-fill': showPassword, 'bi-eye-fill': !showPassword }"></i></button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                                    <div class="input-group">
                                        <input v-if="showConfirmPassword" v-model="userDetails.confirmPassword" type="text"
                                            class="form-control" id="confirmPassword">
                                        <input v-else v-model="userDetails.confirmPassword" type="password"
                                            class="form-control" id="confirmPassword">
                                        <button type="button" class="btn btn-outline-secondary"
                                            @click.prevent="toggleShowConfirmPassword"><i class="bi"
                                                :class="{ 'bi-eye-slash-fill': showConfirmPassword, 'bi-eye-fill': !showConfirmPassword }"></i></button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check form-check-inline">
                                        <input v-model="userDetails.role" class="form-check-input" type="radio"
                                            name="inlineRadioOptions" id="inlineRadio1" value="Partner">
                                        <label class="form-check-label" for="inlineRadio1">Partner</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="userDetails.role" class="form-check-input" type="radio"
                                            name="inlineRadioOptions" id="inlineRadio2" value="Customer">
                                        <label class="form-check-label" for="inlineRadio2">Customer</label>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" @click="closeModalForm">Cancel</button>
                            <button type="submit" class="btn btn-primary text-white" @click.prevent="addUser">Add
                                User</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from "vuex"
import { ref, watch, reactive, onMounted, computed } from "vue"
import { Modal, Toast } from 'bootstrap'
import Pagination from "@/components/Pagination.vue"
import ReservationTable from "@/components/ReservationTable.vue"
import { is } from "@babel/types"

export default {
    components: {
        Pagination,
        ReservationTable,
    },
    data: () => ({
        role: '',
        showPassword: false,
        showConfirmPassword: false,
        mySVG: require('@/assets/svg/people_search.svg'),
        isActiveHeader: '',
        selectedUser: {},
        nameInitial: '',
        tbleColumnName: 'Reserve user from',
        name: '',
        email: '',
        slug: '',
        reserveLogs: [],
    }),

    computed: {
        ...mapGetters('main', ['getReserveLogs'])
    },
    methods: {
        async getMoreDetails(item) {
            this.selectedUser = item
            this.nameInitial = item.name.slice(0, 2).toUpperCase()
            await this.$store.dispatch('main/fetchReserveLogs', { 'from': 'establishments', 'slugs': item.slug })
            this.name = item.user.name
            this.email = item.user.email
            this.slug = item.user.slug
            this.reserveLogs = this.getReserveLogs
        },
        roleColor(role) {
            if (role === 'Customer') {
                return 'text-primary'
            } else {
                return 'text-success'
            }
        },
        statusColor(status) {
            if (status === 'banned') {
                return 'text-danger'
            } else if (status === 'approved') {
                return 'text-success'
            } else if (status === 'pending') {
                return 'text-warning'
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword
        },
        toggleShowConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword
        },
        setNameActive(isActiveName) {
            this.isActiveHeader = isActiveName
        },
    },
    created() {
        this.isUser = false
    },
    setup() {

        const modalDeleteRef = ref(null)
        const modalConfirmRef = ref(null)
        const store = useStore()
        const notificationMessage = computed(() => store.getters['main/getNotificationMessage'])
        const establishmentLists = computed(() => store.getters['main/establishmentLists'])
        const usersCount = computed(() => store.getters['main/usersCount'])
        let toastMesssage = ''
        let toastClass = 'text-bg-success'

        let isUpdateUser = ref(false)
        let bannedUser = ref([])
        let searchText = ref('')
        let allSelected = ref(false)
        let isAscName = ref(true)
        let order = ref('')
        let column = ref('')

        const allLists = ref(null)

        watch(searchText, (currentValue, oldValue) => {
            const pageNumber = 1
            searchLists(currentValue, pageNumber)
        });

        watch(bannedUser, (currentValue, oldValue) => {

            if (bannedUser.value.length > 0) {
                allLists.value.indeterminate = true
            } else {
                allLists.value.indeterminate = false
            }
        });

        const paginationMeta = computed(() => store.getters['main/getPaginationMeta'])
        const currentPage = computed(() => store.getters['main/getCurrentPage'])
        const totalPages = computed(() => store.getters['main/getTotalPages'])

        const state = reactive({
            modal_delete: null,
            modal_confirm: null,
            userID: 0,
        })

        const userDetails = reactive({
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
        })

        onMounted(() => {
            state.modal_delete = new Modal(modalDeleteRef.value, {
                keyboard: false,
                backdrop: "static",
            });
            state.modal_confirm = new Modal(modalConfirmRef.value, {
                keyboard: false,
                backdrop: "static",
            });
            state.modal_form = new Modal('#modalForm', {
                keyboard: false,
                backdrop: "static",
            });
            state.show_toast = new Toast('#toastMessage')
            state.show_ban_toast = new Toast('#banToast')
            // store.dispatch('main/getestablishmentLists');
            column = 'name'
            order = 'asc'
            fetchData()
            store.dispatch('main/getUsersCount');
        })

        async function fetchData(pageNumber = 1) {
            await store.dispatch('main/getEstablishmentLists', { 'searchText': '', 'pageNumber': pageNumber, 'column': column, 'order': order });
        }

        async function searchLists(search, pageNumber = 1) {
            await store.dispatch('main/getEstablishmentLists', { 'searchText': search, 'pageNumber': pageNumber, 'column': column, 'order': order });
        }

        async function sortLists(sColumn, isActive, sOrder, search) {
            column = sColumn
            order = sOrder
            isAscName.value = isActive
            await store.dispatch('main/getEstablishmentLists', { 'searchText': search, 'pageNumber': 1, 'column': column, 'order': order });
        }

        async function selectAll() {
            if (allSelected.value) {
                bannedUser.value.splice(0)
            } else {
                establishmentLists.value.forEach((user) => {
                    bannedUser.value.push(user.id)
                })
            }
        }

        function openDeleteModal(id) {
            state.userID = id
            state.modal_delete.show()
        }

        function closeDeleteModal() {
            state.modal_delete.hide()
        }

        function openConfirmModal(id) {
            state.userID = id
            state.modal_confirm.show()
        }

        function closeConfirmModal() {
            state.modal_confirm.hide()
        }

        function openModalForm(isUpdate, user, id) {

            isUpdateUser = isUpdate

            if (isUpdateUser) {
                userDetails.id = user.id
                userDetails.email = user.email
                userDetails.name = user.name
                userDetails.role = user.role
            } else {
                userDetails.id = ''
                userDetails.email = ''
                userDetails.name = ''
                userDetails.role = ''
            }
            state.userID = id
            state.modal_form.show()
        }

        function closeModalForm() {
            state.modal_form.hide()
        }

        async function confirmDelete() {
            await store.dispatch('main/deleteUser', {
                id: state.userID
            })
            state.modal_delete.hide()
            showToast()
            await store.dispatch('main/getUsersCount')

            if (notificationMessage.value == "Success") {
                toastMesssage = 'User Deleted'
                toastClass = 'text-bg-success'

            } else {
                toastMesssage = 'Failed to Delete'
                toastClass = 'text-bg-danger'
            }
        }

        async function confirmBusiness() {
            await store.dispatch('main/confirmEstablishment', {
                'detail': {
                    'id': state.userID,
                    'status': 2,
                }
            })
            console.log('message: ', notificationMessage.value)
            if (notificationMessage.value == "Success") {
                toastMesssage = 'Business Confirmed'
                toastClass = 'text-bg-success'

            } else {
                toastMesssage = 'Failed to confirm'
                toastClass = 'text-bg-danger'
            }

            state.modal_confirm.hide()
            fetchData()
            store.dispatch('main/getUsersCount');
            showToast()
        }

        async function addUser() {
            if (isUpdateUser) {
                console.log('id: ', userDetails.id)
                await store.dispatch('main/updateUser', {
                    userDetails
                }).then(() => {
                    userDetails.id = '';
                    userDetails.name = '';
                    userDetails.email = '';
                    userDetails.password = '';
                    userDetails.confirmPassword = '';
                    userDetails.role = '';
                });
            } else {
                await store.dispatch('main/registerUser', {
                    userDetails
                }).then(() => {
                    userDetails.id = '';
                    userDetails.name = '';
                    userDetails.email = '';
                    userDetails.password = '';
                    userDetails.confirmPassword = '';
                    userDetails.role = '';
                });
            }

            showToast()
            await store.dispatch('main/getestablishmentLists')
            await store.dispatch('main/getUsersCount')

            closeModalForm()
        }

        function showToast() {
            state.show_toast.show()
        }

        function closeToast() {
            state.show_toast.hide()
        }

        function unselectAllBan() {
            bannedUser.value.splice(0)
            allLists.value.indeterminate = false
        }

        function showBanToast() {
            state.show_ban_toast.show()
        }

        function closeBanToast() {
            state.show_ban_toast.hide()
            bannedUser.value.splice(0)
            allLists.value.indeterminate = false
        }

        async function confirmBanUsers() {
            await store.dispatch('main/banUsers', { 'bannedUsers': bannedUser, 'status': 0 })
        }


        return {
            userDetails,
            isUpdateUser,
            modalDeleteRef,
            modalConfirmRef,
            notificationMessage,
            establishmentLists,
            currentPage,
            totalPages,
            usersCount,
            toastMesssage,
            searchText,

            toastClass,
            bannedUser,
            allLists,
            allSelected,
            isAscName,
            order,
            column,

            fetchData,
            openDeleteModal,
            closeDeleteModal,
            openConfirmModal,
            closeConfirmModal,
            openModalForm,
            closeModalForm,
            confirmDelete,
            confirmBusiness,
            showToast,
            closeToast,
            showBanToast,
            closeBanToast,
            unselectAllBan,
            confirmBanUsers,
            searchLists,
            selectAll,
            sortLists,

            addUser,

            getestablishmentLists: () => store.dispatch('main/getestablishmentLists'),
            getUsersCount: () => store.dispatch('main/getUsersCount'),
        }
    },
}
</script>