
<template>
    <div v-if="isLoading" class="d-flex min-vh-100 justify-content-center align-items-center bg-transparent">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else class="py-3">
        <div class="container-fluid bg-white p-5 rounded table-responsive mh-100">
            <div class="container-fluid bg-light p-5 mb-5">
                <div class="row">
                    <div class="col">
                        <h1 class="display-5 fw-bold">Outstanding Verifications</h1>
                        <p class="col-md-8 fs-4">List of verified & unverified establishments</p>
                    </div>
                    <div class="col">
                        <img :src="mySVG" class="image-fluid" style="height: 200px;" />
                    </div>
                </div>
            </div>
            <div class="row mb-2 ">
                <div class="col-12">
                    <div class="card-body rounded">
                        <div class="alert alert-info d-flex align-items-center alert-dismissible fade show" role="alert">
                            <p class="fs-6"><span><i class="bi bi-info-circle-fill text-info me-2"></i></span>
                                <span class="fw-bold">{{ establishmentLists.length }}</span> business/es are
                                waiting for verification.
                            </p>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>

            <table class="table table-hover caption-top">
                <caption>
                    <div class="row align-items-center justify-content-between g-3 mb-4">
                        <div class="col col-auto col-lg-4">
                            <div class="input-group ">
                                <span class="input-group-text bg-white text-dark" id="basic-addon1">
                                    <i class="bi bi-search"></i>
                                </span>
                                <input v-model="searchText" type="text" class="form-control" placeholder="Search..."
                                    @change="searchLists(searchText)">
                            </div>
                        </div>
                    </div>
                </caption>


                <thead class="table-light">
                    <tr>
                        <th scope="col">
                            <input class="form-check-input" type="checkbox" id="allLists" ref="allLists"
                                v-model="allSelected" @click="selectAll">
                        </th>
                        <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Name
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'nameDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('name', false, 'desc', searchText); setNameActive('nameDesc')"></i>
                                    <i :class="(isActiveHeader === 'nameAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill fs-6"
                                        @click="sortLists('name', true, 'asc', searchText); setNameActive('nameAsc')"></i>
                                </div>
                            </div>
                        </th>
                        <th scope="col">Email</th>
                        <!-- <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Province
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'provinceDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('province', !isAscName, 'desc', searchText); setNameActive('provinceDesc')"></i>
                                    <i :class="(isActiveHeader === 'provinceAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill"
                                        @click="sortLists('province', isAscName, 'asc', searchText); setNameActive('provinceAsc')"></i>

                                </div>
                            </div>
                        </th> -->
                        <!-- <th scope="col" data-sortable="true">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    City/Town
                                </div>
                                <div>
                                    <i :class="(isActiveHeader === 'townDesc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-down-fill fs-6"
                                        @click="sortLists('city_or_town', !isAscName, 'desc', searchText); setNameActive('townDesc')"></i>
                                    <i :class="(isActiveHeader === 'townAsc') ? 'text-primary' : 'text-secondary'"
                                        class="bi bi-caret-up-fill"
                                        @click="sortLists('city_or_town', isAscName, 'asc', searchText); setNameActive('townAsc')"></i>

                                </div>
                            </div>
                        </th> -->
                        <th scope="col">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Documents
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    Verification Status
                                </div>
                            </div>
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in establishmentLists" :key="item.id">

                        <td class="text-warning">
                            <input class="form-check-input" type="checkbox" :value="item.id" v-model="bannedUser">
                        </td>
                        <td class="text-secondary">
                            <h6 class="fw-semibold fs-6">
                                {{ item.name }} <span><i class="bi ms-1 text-primary fs-6"
                                        :class="item.is_verified ? 'bi-patch-check-fill' : 'bi-patch-check'"></i></span>
                            </h6>
                        </td>
                        <td class="text-secondary">{{ item.user.email }}</td>
                        <!-- <td class="text-secondary">{{ item.province }}</td>
                        <td class="text-secondary">{{ item.city_or_town }}</td> -->
                        <td>
                            <div v-if="item.documents.length > 0" class="d-flex flex-row">
                                <li v-for="document in item.documents" class="list-group-item">
                                    <a :href="document.file_url" target="_blank" rel="noopener noreferrer" type="button"
                                        class="btn btn-link btn-sm">View
                                        File <span><i class="bi bi-box-arrow-up-right"></i></span></a>
                                </li>
                            </div>
                            <li v-else class="list-group-item">
                                <span class="badge text-bg-light "><span><i
                                            class="me-2 bi bi-exclamation-circle-fill"></i></span>No
                                    documents
                                    found.</span>
                            </li>
                        </td>
                        <td class="text-uppercase fs-6">
                            <button @click="openVerifyModal(item.id, 1)"
                                v-if="item.documents.length > 0 && !item.is_verified && item.status == 2" type="button"
                                class="btn btn-outline-primary btn-sm"><span v-if="isLoadingBtn"
                                    class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>Verify</button>
                            <!-- <button @click="openVerifyModal(item.id, 0, item.status)"
                                v-else-if="item.is_verified && item.status == 2" type="button"
                                class="btn btn-outline-secondary btn-sm"><span v-if="isLoadingBtn"
                                    class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>Unverify</button> -->
                        </td>
                        <td class="text-danger">
                            <div class="dropdown-center">
                                <button class="btn btn-sm btn-light" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                </button>
                                <ul class="dropdown-menu py-2">
                                    <li>
                                        <router-link :to="`/establishmentview/${item.slug}`" class="fw-light dropdown-item">
                                            <span class="d-none d-sm-inline">View</span></router-link>
                                    </li>
                                    <li><a class="fs-6 fw-light dropdown-item" href="#">Ban</a></li>

                                    <li v-if="item.status_display === 'pending' && item.status_display !== 'banned'"><a
                                            class="fs-6 fw-light dropdown-item text-success"
                                            @click="openConfirmModal(item.id)">Confirm <i
                                                class="bi bi-check-circle mx-1"></i><span></span></a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>

                    <div class="modal fade" ref="modalVerifyRef" id="modalVerify" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                            <div class="modal-content">

                                <div class="modal-body p-4">
                                    <div class="row justify-content-center">
                                        <div>
                                            <p class="fs-5">Are you sure you want to verify business?</p>
                                            <p class="fs-6 text-secondary fw-light">If you verify this business it will now
                                                have the blue check mark <span><i
                                                        class="bi mx-1 text-primary bi-patch-check-fill fs-6"></i></span>
                                                and will be visible to all reserba users.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary"
                                        @click="closeVerifyModal()">Cancel</button>

                                    <button type="button" class="btn btn-md btn-primary" @click="verifyEstablishment">Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </tbody>
            </table>

            <div class="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
                <div id="banToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
                    data-bs-autohide="false">
                    <div class="toast-header">
                        <strong class="me-auto">Reserba</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                            @click.prevent="closeBanToast"></button>
                    </div>
                    <div class="toast-body">
                        Are you sure you want to ban ({{ bannedUser.length }}) user/s?
                        <div class="d-flex justify-content-around mt-4 pt-2 border-top">
                            <button type="button" class="btn btn-primary btn-sm" @click.prevent="confirmBanUsers">Ban
                                Users</button>
                            <button type="button" class="btn btn-secondary btn-sm"
                                @click.prevent="closeBanToast">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <Pagination :current-page="currentPage" :total-pages="totalPages" :fetch-function="fetchData" />
            </div>

            <div ref="toastMessageRef" class="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="toastMessage" class="toast border-0" :class="toastClass" role="alert" aria-live="assertive"
                    aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            {{ notificationMessage }}
                        </div>
                        <button type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="Close"
                            @click="closeToast"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from "vuex"
import { ref, watch, reactive, onMounted, computed } from "vue"
import { Modal, Toast } from 'bootstrap'
import Pagination from "@/components/Pagination.vue"
import ReservationTable from "@/components/ReservationTable.vue"
import { is } from "@babel/types"

export default {
    components: {
        Pagination,
        ReservationTable,
    },
    data: () => ({
        role: '',
        mySVG: require('@/assets/svg/people_search.svg'),
        isActiveHeader: '',
        selectedUser: {},
        nameInitial: '',
        tbleColumnName: 'Reserve user from',
        name: '',
        email: '',
        slug: '',
        reserveLogs: [],
    }),

    computed: {
        ...mapGetters('main', ['getReserveLogs'])
    },
    methods: {
        async getMoreDetails(item) {
            this.selectedUser = item
            this.nameInitial = item.name.slice(0, 2).toUpperCase()
            await this.$store.dispatch('main/fetchReserveLogs', { 'from': 'establishments', 'slugs': item.slug })
            this.name = item.user.name
            this.email = item.user.email
            this.slug = item.user.slug
            this.reserveLogs = this.getReserveLogs
        },
        roleColor(role) {
            if (role === 'Customer') {
                return 'text-primary'
            } else {
                return 'text-success'
            }
        },
        statusColor(status) {
            if (status === 'banned') {
                return 'text-danger'
            } else if (status === 'approved') {
                return 'text-success'
            } else if (status === 'pending') {
                return 'text-warning'
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword
        },
        setNameActive(isActiveName) {
            this.isActiveHeader = isActiveName
        },
    },
    created() {
        this.isUser = false
    },
    setup() {
        const modalConfirmRef = ref(null)

        const isLoading = ref(true)
        const isLoadingBtn = ref(false)
        const modalVerifyRef = ref(null)
        const verificationStatus = ref(null)

        const store = useStore()
        const notificationMessage = computed(() => store.getters['main/getNotificationMessage'])
        // const establishmentLists = computed(() => store.getters['main/establishmentLists'])
        const establishmentLists = computed(() => store.getters['main/getVerificationLists'])
        const usersCount = computed(() => store.getters['main/usersCount'])
        let toastMesssage = ''
        let toastClass = 'text-bg-success'

        let isUpdateUser = ref(false)
        let bannedUser = ref([])
        let searchText = ref('')
        let allSelected = ref(false)
        let isAscName = ref(true)
        let order = ref('')
        let column = ref('')

        const allLists = ref(null)

        watch(searchText, (currentValue, oldValue) => {
            const pageNumber = 1
            searchLists(currentValue, pageNumber)
        });

        watch(bannedUser, (currentValue, oldValue) => {

            if (bannedUser.value.length > 0) {
                allLists.value.indeterminate = true
            } else {
                allLists.value.indeterminate = false
            }
        });

        const paginationMeta = computed(() => store.getters['main/getPaginationMeta'])
        const currentPage = computed(() => store.getters['main/getCurrentPage'])
        const totalPages = computed(() => store.getters['main/getTotalPages'])

        const state = reactive({
            modal_confirm: null,
            modal_verify: null,
            userID: 0,
            selectedEstablishment: {
                id: null,
                status: null,
            },
        })

        async function fetchData(pageNumber = 1) {
            await store.dispatch('main/fetchVerificationLists', { name: '', status: '2', is_verified: '' })

        }

        async function searchLists(search, pageNumber = 1) {
            await store.dispatch('main/getEstablishmentLists', { 'searchText': search, 'pageNumber': pageNumber, 'column': column, 'order': order });
        }

        async function sortLists(sColumn, isActive, sOrder, search) {
            column = sColumn
            order = sOrder
            isAscName.value = isActive
            await store.dispatch('main/getEstablishmentLists', { 'searchText': search, 'pageNumber': 1, 'column': column, 'order': order });
        }

        async function selectAll() {
            if (allSelected.value) {
                bannedUser.value.splice(0)
            } else {
                establishmentLists.value.forEach((user) => {
                    bannedUser.value.push(user.id)
                })
            }
        }

        function openConfirmModal(id) {
            state.userID = id
            state.modal_confirm.show()
        }

        function closeConfirmModal() {
            state.modal_confirm.hide()
        }

        function openVerifyModal(id, status) {
            state.selectedEstablishment = {
                id: id,
                status: status,
            }
            state.modal_verify.show()
        }

        function closeVerifyModal() {
            state.modal_verify.hide()
        }

        async function confirmBusiness() {
            await store.dispatch('main/confirmEstablishment', {
                'detail': {
                    'id': state.userID,
                    'status': 2,
                }
            })
            if (notificationMessage == "Success") {
                toastMesssage = 'Business Confirmed'
                toastClass = 'text-bg-success'

            } else {
                toastMesssage = 'Failed to confirm'
                toastClass = 'text-bg-danger'
            }

            state.modal_confirm.hide()
            fetchData()
            store.dispatch('main/getUsersCount');
            showToast()
        }

        function showToast() {
            state.show_toast.show()
        }

        function closeToast() {
            state.show_toast.hide()
        }

        function unselectAllBan() {
            bannedUser.value.splice(0)
            allLists.value.indeterminate = false
        }

        function showBanToast() {
            state.show_ban_toast.show()
        }

        function closeBanToast() {
            state.show_ban_toast.hide()
            bannedUser.value.splice(0)
            allLists.value.indeterminate = false
        }

        async function confirmBanUsers() {
            await store.dispatch('main/banUsers', { 'bannedUsers': bannedUser, 'status': 0 })
        }

        async function verifyEstablishment() {
            isLoadingBtn.value = true
            await store.dispatch('main/verifyEstablishment', {
                'detail': {
                    'id': state.selectedEstablishment.id,
                    'status': state.selectedEstablishment.status,
                }
            })

            fetchData()

            isLoadingBtn.value = false

            showToast()

            state.modal_verify.hide()
        }

        onMounted(() => {
            // store.dispatch('main/getestablishmentLists');
            column = 'name'
            order = 'asc'
            fetchData()
            store.dispatch('main/getUsersCount');

            isLoading.value = false

            setTimeout(() => {
                state.modal_verify = new Modal(modalVerifyRef.value, {
                    keyboard: false,
                    backdrop: "static",
                });
                state.show_toast = new Toast('#toastMessage')
                state.show_ban_toast = new Toast('#banToast')
            }, 1000)
        })


        return {
            isUpdateUser,
            modalConfirmRef,
            notificationMessage,
            establishmentLists,
            currentPage,
            totalPages,
            usersCount,
            toastMesssage,
            searchText,

            toastClass,
            bannedUser,
            allLists,
            allSelected,
            isAscName,
            order,
            column,

            isLoading,
            isLoadingBtn,
            modalVerifyRef,
            verificationStatus,

            fetchData,
            openConfirmModal,
            closeConfirmModal,
            confirmBusiness,
            showToast,
            closeToast,
            showBanToast,
            closeBanToast,
            unselectAllBan,
            confirmBanUsers,
            searchLists,
            selectAll,
            sortLists,

            verifyEstablishment,
            openVerifyModal,
            closeVerifyModal,

            getestablishmentLists: () => store.dispatch('main/getestablishmentLists'),
            getUsersCount: () => store.dispatch('main/getUsersCount'),
        }
    },
}
</script>