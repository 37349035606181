<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center row align-items-center">
            <div class=" col-12">
                <img :src="mySVG" class="image-fluid" />
            </div>
            <div class="col-12 mt-5 text-center">
                <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
                <p class="lead">
                    The page you’re looking for doesn’t exist.
                </p>
                <router-link to="/" class="btn btn-primary">Go Home</router-link>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mySVG: require('../assets/svg/page_not_found.svg')
        }
    }
}
</script>